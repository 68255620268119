import * as React from 'react';
import Box from '@mui/material/Box';
import { Outlet } from "react-router-dom";
export default function Layout () {
  return (
    <React.Fragment>
      <Box>
        <Outlet />
      </Box>
    </React.Fragment>
  )
}
