import { createTheme } from '@mui/material/styles';

import Futura from './assets/fonts/futura/FuturaNDforNike365Cn-XBd.woff2';
import Palatino from './assets/fonts/palatino/Palatino.ttc'
import HelveticaNeueMedium from './assets/fonts/helveticaNeue/HelveticaNeue-Medium.woff2'

export const theme = createTheme({
  typography: {
    fontFamily: 'Futura',
    h1: {
      fontWeight: 'bold',
      fontSize: 50,
      letterSpacing: '-2px',
      lineHeight: '.9'
    },
    subtitle2: {
      fontFamily: 'Palatino',
      fontStyle: 'italic',
      fontSize: 10,
    },
    body1: {
      fontWeight: 500,
      fontFamily: 'HelveticaNeueMedium'
    },
    button: {
      fontStyle: 'italic',
    },
  },
  palette: {
    primary: {
      main: '#111111',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Futura';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Futura'), local('Futura'), url(${Futura}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Palatino';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Palatino'), local('Palatino'), url(${Palatino}) format('ttc');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'HelveticaNeueMedium';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('HelveticaNeueMedium'), local('HelveticaNeueMedium'), url(${HelveticaNeueMedium}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});
