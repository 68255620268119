import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import swoosh from '../../../assets/Swoosh.svg'

export default function Home () {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw'
    }}>
      <Box
        sx={{
          maxWidth: {
            xs: '50vw',
            sm: '50vw'
          },
          paddingBottom: '80px',
        }}
      >
        <Box
          py={3}
          sx={{
            textAlign: 'center',
            paddingBottom: '64px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: '5%',
            }}
            mb={4}
          >
            <img
              src={swoosh}
              alt="Nike Swoosh"
              width='30%'
            />
          </Box>
          <Typography
            variant="h1"
            sx={{
              fontSize: {
                xs: '300%',
                sm: '350%',
                lg: '600%',
              },
              textTransform: 'uppercase',
              fontWeight: 'bold'
            }}
          >Welcome to</Typography>
          <Typography
            variant="h1"
            sx={{
              fontSize: {
                xs: '300%',
                sm: '350%',
                lg: '600%',
              },
              textTransform: 'uppercase',
              fontWeight: 'bold'
            }}
          >Nike.Gallery</Typography>
        </Box>
        <Box
          sx={{
            textAlign: 'center'
          }}
        >
          <Typography 
            variant="subtitle2"
            sx={{fontSize: '130%'}}
          >Current Projects:</Typography>
          <Link href="/yourmove/">
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'bold',
                fontSize: '150%',
              }}
            >Your Move</Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
